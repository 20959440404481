import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Btn = props => {
  return (
    <Link className="" to={props.path}>
      <div className="is-flex is-justify-content-flex-end mb-1">
        <div>
          <span className="is-size-7 has-letter-spacing-1 is-align-self-center">
            {props.description}
          </span>
        </div>
        <div className="pt-1 ml-3 has-opacity-8 is-align-self-center">
          <StaticImage
            src="../images/arrow-right-thin.svg"
            alt="Arrow right"
            placeholder="blurred"
            layout="fixed"
            height={18}
          />

          {/* <span className="material-icons-outlined md-18 ml-2 mt-2px has-opacity-8">
                  share
                </span> */}
        </div>
      </div>
    </Link>
  )
}

export default Btn
